
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRouter } from "vue-router";
import {designation_list} from "@/core/data/genericData";
import axios from "axios";
import JwtService from "@/core/services/JwtService";
import {mask} from 'vue-the-mask'
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "add-kmp-modal",
  directives: {mask},
  props: {    
    id: { required: true }
  },
  components: {
    
    },
  setup(props) {
    const store = useStore();
    const designation_item = designation_list;    
    const formRef = ref<null | HTMLFormElement>(null);
    const addkmpModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const loadingDin = ref<boolean>(false);
    const router = useRouter();    

    const formData = ref({
      name: "",      
      din : "",
      dob : "",
      gender : "",      
      mobile : "",
      email : "",
      designation_select : ""
    });    

    const rules = ref({
      name: [
        {
          required: true,
          message: "Name is required",
          trigger: "change",          
        },        
      ],
      email: [
        {
          required: true,
          message: "Email is required",
          trigger: "change",
        },        
      ],
      mobile: [
        {
          required: true,
          message: "Mobile is required",
          trigger: "change",
          min: 10,
          max: 10
        },
      ],
      din: [
        {
          required: true,
          message: "DIN is required",
          trigger: "change",
          min: 8,
          max: 8
        },
      ],      
      dob: [
        {
          required: true,
          message: "DOB is required",
          trigger: "change",
        },
      ],
      designation_select: [
        {
          required: true,
          message: "Designation is required",
          trigger: "change",
        },
      ],
      gender: [
        {
          required: true,
          message: "Gender is required",
          trigger: "change",
        },
      ]       
    });    

    const setCompanyData = async (data) => {

      var user = JSON.parse(JwtService.getToken());

      var dt = ("0" + data.dob.getDate()).slice(-2)
      var mo = ("0" + (data.dob.getMonth() + 1)).slice(-2)
      var yy = data.dob.getFullYear()
      var dtt = (yy+'-'+mo+'-'+dt);

      const db_data = {
         "kmp_id":0,
        "kmp_name": data.name,
        "kmp_din": data.din,
        "kmp_designation_id" : data.designation_select,
        "kmp_gender_id" : data.gender == "Male" ? 1 : 2,
        "kmp_email_id" : data.email,
        "kmp_mobile_no" : data.mobile,
        "kmp_dob" : dtt,
        "active": 1,
        "user_id": user.user_id,
        }                                                                                                                                                                                                                                               

      await store.dispatch(Actions.CUST_ADD_KMP, db_data).then(({ data }) => {

        if (data){

            setTimeout(() => {
              loading.value = false;
              Swal.fire({
                text: "KMP has been successfully created.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(addkmpModalRef.value);   
                router.go(0)           
              });
            }, 2000)          

          } else {

            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            })  
            return false;
          }
      
      })
      .catch(({ response }) => {
        console.log(response);        
      });

    }    

    const submit = async () => {
      
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate (async (valid) => {
        if (valid) {
          await setCompanyData(formData.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };

    return {
      formData,
      designation_item,
      rules,
      submit,
      formRef,
      loading,
      loadingDin,
      addkmpModalRef,
    };
  },
});
